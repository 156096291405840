/*
 * @Descripttion : 校验
 * @version      :
 * @Author       : ml
 * @Date         : 2020-09-09 18:11:30
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-10 09:55:04
 */

export function validatePhone(rule, value, callback) {
	const reg = /^[1][3-9][0-9]{9}$/
	if (value == '' || value == undefined || value == null) {
		callback()
	} else {
		if (!reg.test(value) && value != '') {
			callback(new Error('请输入正确的手机号码'))
		} else {
			callback()
		}
	}
}

export function validateTel(rule, value, callback) {
	const reg = /^([0-9]{3,4}-)?[0-9]{7,8}$/
	if (value == '' || value == undefined || value == null) {
		callback()
	} else {
		if (!reg.test(value) && value != '') {
			callback(new Error('请输入正确的电话号码'))
		} else {
			callback()
		}
	}
}

export function validatePhoneTwo(rule, value, callback) {
	const reg = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/
	if (value == '' || value == undefined || value == null) {
		callback()
	} else {
		if (!reg.test(value) && value != '') {
			callback(new Error('请输入正确的电话号码或者固话号码'))
		} else {
			callback()
		}
	}
}

export function validatePhoneThree(rule, value, callback) {
	const reg = /^(((13[0-9]{1})|14[0-9]{1}|15[0-9]{1}|16[0-9]{1}|17[0-9]{1}|18[0-9]{1}|19[0-9]{1})+\d{8})$/;
	if (!value) {
		callback(new Error('请输入手机号码'))
	} else if (!reg.test(value) && value != '') {
		callback(new Error('请输入正确的手机号码'))
	} else {
		callback()
	}
}