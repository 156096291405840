/*
 * @Descripttion : 供应商管理
 * @version      :
 * @Author       : ml
 * @Date         : 2020-09-01 13:25:56
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-10 11:34:27
 */
import request from './request'

// 供应商列表
export function getSupplierList(data) {
	return request.fetch('suppliers/supplierList', data)
}

// 获取供应商详情
export function getSupplierByID(id) {
	return request.fetch('suppliers/' + id)
}

// 新增供应商
export function addSupplier(data) {
	return request.post('suppliers', data)
}

// 修改供应商
export function updateSupplier(data) {
	return request.put('suppliers', data)
}

// 删除供应商
export function delSupplier(id) {
	return request.delete('suppliers/' + id)
}

// 获取供应商用户分页列表
export function getSupplierUserList(data) {
	return request.fetch('users/supplierUserList', data)
}

// 保存供应商用户
export function addSupplierUser(data) {
	return request.post('users/saveSupplierUser', data)
}

// 修改供应商用户
export function updateSupplierUser(data) {
	return request.put('users/updateSupplierUser', data)
}

// 根据用户id获取用户
export function getSupplierUserByID(id) {
	return request.fetch('users/supplierUserDetails/' + id)
}

// 获取供应商下拉列表
export function getSupperPulldownList() {
	return request.fetch('suppliers/supperPulldownList')
}
