<!--
 * @Descripttion : 
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-09-03 13:19:21
 * @LastEditors  : min
 * @LastEditTime : 2020-12-22 15:34:10
-->
<template>
    <div class="ml_certification_order">
        <div style="padding:10px 0;">
            <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
                <a-form-model-item label="订单创建">
                    <a-radio-group v-model="fastData" @change="fastDataChange">
                        <a-radio-button value="0">
                            近7天
                        </a-radio-button>
                        <a-radio-button value="1">
                            近30天
                        </a-radio-button>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item>
                    <a-range-picker v-model="freeDate" style="max-width:250px;" @change="onChange" />
                </a-form-model-item>

                <a-form-model-item label="平台审核">
                    <a-range-picker style="max-width:250px;" @change="onChangeAuditTime" />
                </a-form-model-item>

                <a-form-model-item label="订单完成">
                    <a-range-picker style="max-width:250px;" @change="onChangeOverTime" />
                </a-form-model-item>

                <a-form-model-item label="办证类型">
                    <a-select style="width:105px;" v-model="formInline.type" allowClear placeholder="请选择">
                        <a-select-option :value="1">平台办理</a-select-option>
                        <a-select-option :value="2">商户自办</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="逾期状态">
                    <a-select style="width:105px;" v-model="formInline.overdueStatus" allowClear placeholder="请选择">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="1">未逾期</a-select-option>
                        <a-select-option :value="2">即将逾期</a-select-option>
                        <a-select-option :value="3">已逾期</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="创建来源">
                    <a-select style="width:105px;" v-model="formInline.source" allowClear placeholder="请选择">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="1">系统创建</a-select-option>
                        <a-select-option :value="2">人工创建</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item>
                    <a-input-search v-model="formInline.keyWord" placeholder="机构、车商、车牌" allowClear enter-button @search="getList" />
                </a-form-model-item>

                <a-form-model-item>
                    <a-button @click="onResetQuery">重置</a-button>
                </a-form-model-item>

                <a-form-model-item v-if="permissions.indexOf('certificationOrder:add')>-1">
                    <a-button type="primary" icon="plus" @click="addEditOrderFn" />
                </a-form-model-item>
                <a-form-model-item>
                    <a-button type="primary" shape="round" icon="download" v-if="permissions.indexOf('certificationOrder:export')>-1" @click="downExport" />
                </a-form-model-item>
            </a-form-model>
        </div>

        <a-radio-group v-model="formInline.status" @change="onChangeStatus" style="margin-bottom:10px;">
            <a-radio-button :value="0">
                待派单({{waitingCount}})
            </a-radio-button>
            <a-radio-button :value="1">
                办证中({{processingCount}})
            </a-radio-button>
            <a-radio-button :value="2">
                平台待审核({{platformWaitAuditCount}})
            </a-radio-button>
            <a-radio-button :value="3">
                机构待审核({{institutionWaitAuditCount}})
            </a-radio-button>
            <a-radio-button :value="4">
                已完结({{endCount}})
            </a-radio-button>
            <a-radio-button :value="5">
                驳回({{rejectCount}})
            </a-radio-button>
            <a-radio-button :value="6">
                即将逾期({{toOverdueCount}})
            </a-radio-button>
            <a-radio-button :value="7">
                已逾期({{overdueCount}})
            </a-radio-button>

        </a-radio-group>

        <a-table :columns="columns" bordered :data-source="data" :rowKey='record=>record.id' :pagination="pagination" :loading="loading" @change="handleTableChange" size="middle">
            <template slot="operationCar" slot-scope="record">
                <!-- <div>
          订单编号：{{record.orderNumber||'-'}}
        </div> -->
                <div>
                    名称：{{record.carName||'-'}}
                </div>
                <div>
                    车牌：{{record.carPlate||'-'}}
                </div>
                <div>
                    机构：{{record.carInstitutionName||'-'}} {{record.carInstitutionStoreName||'-'}}
                </div>
                <div>
                    存放地：{{record.carInstitutionStoreAddress||'-'}}
                </div>
                <div>
                    创建时间：{{record.createTime||'-'}}
                </div>
                <div>
                    完结时间：{{record.overTime||'-'}}
                </div>
            </template>

            <template slot="type" slot-scope="record">
                <span v-if="record.type==1">平台办理</span>
                <span v-if="record.type==2">商户自办</span>
            </template>

            <template slot="transferType" slot-scope="record">
                <span v-if="record.transferType==1">本市过户</span>
                <span v-if="record.transferType==2">转籍提档</span>
            </template>

            <template slot="memberInfo" slot-scope="record">
                <div>
                    姓名：{{record.merchantName||'-'}}
                </div>
                <div>
                    电话：{{record.merchantPhone||'-'}}
                </div>
                <div>
                    所属机构：{{record.merchantInstitutionName||'-'}}
                </div>
            </template>

            <template slot="operation" slot-scope="record">
                <a-space>
                    <a-dropdown :trigger="['click']">
                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                            操作
                            <a-icon type="down" />
                        </a>
                        <a-menu slot="overlay">
                            <a-menu-item key="0" @click="detailOrder(record)">
                                查看
                            </a-menu-item>
                            <a-menu-item key="7" @click="addEditOrderFn(record)" v-if="permissions.indexOf('certificationOrder:edit')>-1&&record.haveModifyPermission==1">
                                修改
                            </a-menu-item>
                            <a-menu-item key="1" v-if="permissions.indexOf('certificationOrder:sendorders')>-1&&(formInline.status==0||formInline.status==1&&!record.supplierNumber)" @click="sendOrders(record)">
                                手动派单
                            </a-menu-item>
                            <a-menu-item key="9" v-if="record.isShowPickUpCar==1" @click="savePickUpCarFn(record)">
                                提车指派
                            </a-menu-item>
                            <a-menu-item key="10" v-if="record.isShowUpdatePickUpCar==1" @click="updatePickUpCarFn(record)">
                                编辑提车单
                            </a-menu-item>
                            <a-menu-item key="2" v-if="permissions.indexOf('certificationOrder:upload')>-1&&(formInline.status==1||formInline.status==2||formInline.status==5||formInline.status==6)" @click="detailOrderHandle(record,'isEdit')">
                                处理
                            </a-menu-item>
                            <a-menu-item key="3" v-if="permissions.indexOf('certificationOrder:audit')>-1&&formInline.status==2" @click="detailOrderHandle(record)">
                                审核
                            </a-menu-item>
                            <a-menu-item key="4" v-if="permissions.indexOf('certificationOrder:followup')>-1" @click="followUpHandle(record)">
                                跟进
                            </a-menu-item>
                            <a-menu-item key="5" @click="followUpListHandle(record)">
                                跟进记录
                            </a-menu-item>
                            <a-menu-item key="6" v-if="permissions.indexOf('certificationOrder:finish')>-1&&formInline.status==3" @click="orderFinish(record)">
                                完成
                            </a-menu-item>
                            <a-menu-item key="8" v-if="record.isShowRemark==1" @click="remarksFn(record)">备注</a-menu-item>
                            <a-menu-item key="11" v-if="permissions.indexOf('certificationOrder:changeTransferType')>-1&&(formInline.status==0||formInline.status==1||formInline.status==5)" @click="onOpenTransferTypeFn(record)">
                                变更过户类型
                            </a-menu-item>
                            <a-menu-item key="12" v-if="permissions.indexOf('certificationOrder:changeSupplier')>-1&&formInline.status==1" @click="onOpenSupplier(record)">
                                变更供应商
                            </a-menu-item>
                            <!-- 已完成->平台待审核,机构待审核->平台待审核,办证中->待派单 -->
                            <a-menu-item key="13" v-if="record.isShowChangeOrderStatus==1" @click="changeOrderStatus(record)">
                                变更订单状态
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </a-space>
            </template>
        </a-table>

        <a-modal v-model="modalVisible" title="手动派单" centered @ok="saveSendOrder" :destroyOnClose="true">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-form-model-item label="供应商" prop="supplierNumberArray">
                    <a-cascader v-model="form.supplierNumberArray" :options="options" placeholder="请选择供应商" :fieldNames="{ label: 'name', value: 'number', children: 'supplierTree' }" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <a-modal v-model="modalRemarkVisible" title="跟进备注" centered @ok="saveFollowUp" :destroyOnClose="true">
            <a-form-model>
                <a-form-model-item label="">
                    <a-textarea v-model="remark" placeholder="请输入跟进备注" :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <a-modal v-model="finishRemarkVisible" title="完成过户订单" centered @ok="saveFinish" :destroyOnClose="true">
            <a-form-model ref="finishForm" :model="formFinish" :rules="rulesFinish" layout="horizontal" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-form-model-item label="完成备注" prop="remarkFinish">
                    <a-textarea v-model="formFinish.remarkFinish" placeholder="请输入完成备注" :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
                <a-form-model-item label="图片备注" prop="">
                    <template v-for="(items,indexs) in fileList">
                        <filesUpload :key="indexs" :fileObj="items" @onChange="uploadDone"></filesUpload>
                    </template>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <a-modal v-model="followUpListVisible" title="跟进记录" centered :footer="null" width="70%" :destroyOnClose="true">
            <a-table :columns="followUpColumns" bordered :data-source="followUpData" :rowKey='record=>record.id' :pagination="false" :loading="followUpLoading" size="middle">
            </a-table>
        </a-modal>

        <!-- <a-drawer :title="orderDetailTitle" placement="bottom" :visible="orderDetailVisible" v-if="orderDetailVisible"
      width="100%" height="100%" @close="handleCancel" wrapClassName="ml_drawer_class">
      <div style="height:calc(100vh - 55px);overflow: auto;padding:0 20px 20px 20px;">
        <orderDetail :orderObj="orderObj" v-if="orderObj" @onSave="onUploadSave"></orderDetail>
      </div>
    </a-drawer> -->

        <!-- 新增/编辑订单 -->
        <orderInfoAddEdit ref="addEditOrder" @refresh="getList"></orderInfoAddEdit>

        <!-- 备注 -->
        <orderRemarks ref="remarks" @refresh="getList"></orderRemarks>

        <!-- 提车指派 -->
        <orderPickUpCar ref="pickUpCar" @refresh="getList"></orderPickUpCar>

        <!-- 编辑交车单 -->
        <a-drawer title="编辑交车单" width="600px" :visible="visibleDeliverCar" :destroyOnClose="true" @close="onCloseDeliverCar" maskClosable bodyStyle="padding-top:20px">
            <orderDeliverCar ref="deliverCar" @refresh="getList" @closeDeliverCar="onCloseDeliverCar" :orderCurNumber="orderCurNumber" :isErp="0"></orderDeliverCar>
        </a-drawer>

        <a-modal v-model="visibleTransferType" title="过户类型变更" centered @ok="onChangeTransferTypeFn" :destroyOnClose="true">
            <a-form-model ref="refFormTransferType" :model="formTransferType" :rules="rulesformTransferType" layout="horizontal" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-form-model-item label="当前状态" prop="">
                    {{transferTypeList.transferTypeName}}
                </a-form-model-item>
                <a-form-model-item label="更改为" prop="transferType">
                    <a-radio-group v-model="formTransferType.transferType">
                        <a-radio :style="radioStyle" :value="item.value" v-for="(item,index) in transferTypeList.transferTypeOptions" :key="index">
                            {{item.label}}
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <a-modal v-model="visibleSupplier" title="供应商变更" centered @ok="onChangeSupplier" :destroyOnClose="true">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-form-model-item label="当前供应商" prop="">
                    {{supplierName||'-'}}
                </a-form-model-item>
                <a-form-model-item label="供应商" prop="supplierNumberArray">
                    <a-cascader v-model="form.supplierNumberArray" :options="options" placeholder="请选择供应商" :fieldNames="{ label: 'name', value: 'number', children: 'supplierTree' }" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <a-modal :visible="visitOrderStatus" title="过户类型变更" centered :destroyOnClose="true" @cancel="hideVisitOrder()" @ok="submitOrderStatus()" :width="550">
            <div class="ml_visitOrderStatus_content">
                <span class="label">当前状态：</span>
                {{orderStatusInfo.statusName}}
            </div>
            <div class="ml_visitOrderStatus_content">
                <span class="label">更改为：</span>
                <a-radio-group v-model="orderStatus">
                    <a-radio v-for="item in orderStatusInfo.statusOptions" :key="item.value" :value="item.value">{{item.label}}</a-radio>
                </a-radio-group>
            </div>
        </a-modal>

    </div>
</template>
<script>
import {
    delOrder,
    getOrderList,
    sendOrders,
    followUp,
    finish,
    downExport,
    getByMerchantPhone,
    getTransferType,
    editTransferType,
    getSupplier,
    editSupplier,
    changeCertificationOrders,
    getCertificationOrdersStatusInfo
} from "@/api/order";

import { getSupperPulldownList } from "@/api/supplier";

import { mapState } from "vuex";

// import orderDetail from "@/components/component-order-detail/component-order-detaile";

import moment from "moment";
import "moment/locale/zh-cn";
//components
import orderInfoAddEdit from "../../components/orderManage/orderInfoAddEdit";
import orderRemarks from "../../components/orderManage/remarks";
import orderPickUpCar from "../../components/pickUpCar/pickUpCarOrderForm";
import orderDeliverCar from "../../components/pickUpCar/pickUpCarEditForm";
import filesUpload from "@/components/pickUpCar/filesUpload";

const columns = [
    { title: "ID", dataIndex: "no", align: "center" },
    { title: "车辆信息", scopedSlots: { customRender: "operationCar" } },
    { title: "订单类型", scopedSlots: { customRender: "type" }, align: "center" },
    { title: "过户类型", scopedSlots: { customRender: "transferType" }, align: "center" },
    { title: "会员信息", scopedSlots: { customRender: "memberInfo" } },
    { title: "逾期状态", dataIndex: "overdueStatusName", align: "center" },
    { title: "派单人", dataIndex: "dispatchUser", align: "center" },
    { title: "供应商", dataIndex: "supplierName", align: "center" },
    { title: "派单时间", dataIndex: "dispatchTime", align: "center" },
    // { title: "证件核对", dataIndex: "dispatchTime", align: "center" },
    { title: "证件核验状态", dataIndex: "invoiceCheckStatusName", align: "center" },
    { title: "跟进人", dataIndex: "followUpUserName", align: "center" },
    { title: "操作", scopedSlots: { customRender: "operation" }, align: "center" }
];

const followUpColumns = [
    { title: "商户名称", dataIndex: "merchantName", align: "center" },
    { title: "商户电话", dataIndex: "merchantPhone", align: "center" },
    { title: "订单号", dataIndex: "orderNumber", align: "center" },
    { title: "车牌号", dataIndex: "carPlate", align: "center" },
    { title: "跟进人", dataIndex: "followUpUserName", align: "center" },
    { title: "跟进时间", dataIndex: "createTime", align: "center" },
    { title: "备注", dataIndex: "remark", align: "center" },
];

export default {
    name: "certificationOrder",
    components: {
        // orderDetail,
        orderInfoAddEdit,
        orderRemarks,
        orderPickUpCar,
        orderDeliverCar,
        filesUpload
    },
    data() {
        return {
            columns,
            data: [],
            loading: false,
            pagination: {
                pageSize: 10,
                pageSizeOptions: ["10", "20", "30", "40"],
                showSizeChanger: true,
                current: 1,
                total: 0
            },
            followUpColumns,
            followUpData: [],
            followUpLoading: false,

            form: {
                supplierNumberArray: [],
                supplierNumber: "",
                orderNumber: ""
            },
            rules: {
                supplierNumberArray: [{ required: true, message: "请选择供应商" }]
            },
            formInline: {
                keyWord: "",
                status: 0,
                createTimeStart: null,
                createTimeEnd: null,
                type: undefined,
                source: undefined,
                overdueStatus: undefined,
                auditTimeBegin: null,
                auditTimeEnd: null,
                overTimeBegin: null,
                overTimeEnd: null,
            },
            modalVisible: false,
            modalRemarkVisible: false,
            remarkOrderNumber: "",
            remark: "",
            followUpListVisible: false,
            options: [],
            processingCount: 0,
            rejectCount: 0,
            platformWaitAuditCount: 0,
            institutionWaitAuditCount: 0,
            endCount: 0,
            waitingCount: 0, //待派单数量
            fastData: "",
            freeDate: [],
            auditTime: [],  //平台审核时间
            overTim: [], //订单完成时间
            finishRemarkVisible: false, //  是否显示完成备注
            formFinish: {
                finishOrderNumber: "", //订单编号
                remarkFinish: "", //  完成备注内容
                imgUrls: [], //图片
            },
            rulesFinish: {
                remarkFinish: [{ required: true, message: "请填写完成备注" }]
            },
            // orderDetailVisible: false,  //  是否显示订单详情
            // orderDetailTitle: "",  //  弹窗标题
            // orderNumber: "", //  订单编号
            // orderObj: null //  订单详情
            toOverdueCount: 0, // 即将逾期
            overdueCount: 0, // 已逾期
            visibleDeliverCar: false,
            orderCurNumber: null,
            fileList: [], //上传图片
            //修改订单过户类型_str
            visibleTransferType: false,
            transferTypeList: {},
            formTransferType: {
                id: 0,
                transferType: undefined
            },
            rulesformTransferType: {
                transferType: [{ required: true, message: "请选择过户类型" }]
            },
            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
            },
            //修改订单过户类型_end
            //修改供应商_str
            visibleSupplier: false,
            supplierName: '',
            //修改供应商_end

            visitOrderStatus:false,//改变订单状态modal
            orderStatusInfo:{},
            orderStatus:null,
            changeOrderStatusId:null
        };
    },
    computed: {
        ...mapState({
            permissions: state => state.userStore.permissions
        })
    },
    mounted() {
        this.getList();
        if (this.permissions.indexOf("certificationOrder:sendorders") > -1) {
            getSupperPulldownList().then(res => {
                this.options = res.data;
            });
        }
    },
    methods: {
        getList() {
            const that = this;
            that.loading = true;
            getOrderList({
                createTimeStart: that.formInline.createTimeStart,
                createTimeEnd: that.formInline.createTimeEnd,
                keyWord: that.formInline.keyWord,
                status: that.formInline.status,
                pageNum: that.pagination.current,
                pageSize: that.pagination.pageSize,
                type: that.formInline.type,
                source: that.formInline.source,
                overdueStatus: that.formInline.overdueStatus,
                auditTimeBegin: that.formInline.auditTimeBegin,
                auditTimeEnd: that.formInline.auditTimeEnd,
                overTimeBegin: that.formInline.overTimeBegin,
                overTimeEnd: that.formInline.overTimeEnd,
                _: new Date().getTime()
            })
                .then(res => {
                    that.loading = false;
                    that.data = res.data.pageList.list;
                    that.pagination.total = res.data.pageList.total;

                    that.processingCount = res.data.processingCount;
                    that.rejectCount = res.data.rejectCount;
                    that.platformWaitAuditCount = res.data.platformWaitAuditCount;
                    that.institutionWaitAuditCount = res.data.institutionWaitAuditCount;
                    that.endCount = res.data.endCount;
                    that.waitingCount = res.data.waitingCount; //待派单
                    that.toOverdueCount = res.data.toOverdueCount;
                    that.overdueCount = res.data.overdueCount;
                })
                .catch(err => {
                    console.log(err);
                    that.loading = false;
                });
        },
        onChange(date, dateString) {
            console.log(date, dateString);
            this.formInline.createTimeStart = dateString[0];
            this.formInline.createTimeEnd = dateString[1];
        },
        onChangeAuditTime(date, dateString) {
            console.log(date, dateString);
            this.formInline.auditTimeBegin = dateString[0];
            this.formInline.auditTimeEnd = dateString[1];
        },
        onChangeOverTime(date, dateString) {
            console.log(date, dateString);
            this.formInline.overTimeBegin = dateString[0];
            this.formInline.overTimeEnd = dateString[1];
        },
        handleTableChange(pagination, filters, sorter) {
            console.log(pagination, filters, sorter);
            this.pagination = { ...pagination };
            this.getList();
        },
        onChangeStatus() {
            this.pagination.current = 1;
            this.getList();
        },
        detailOrder(e) {
            // 订单详情
            /**
             * 只看详情
             */
            this.$router.push({
                path: "/orderDetailView",
                query: {
                    orderno: e.orderNumber
                }
            });
        },
        detailOrderHandle(e,isEdit) {
            // 订单详情-处理、审核
            /**
             * 有相关操作
             */
            // isEdit==true 平台审核-处理-->编辑
            this.$router.push({
                path: "/orderDetail",
                query: {
                    orderno: e.orderNumber,
                    isEdit:isEdit
                }
            });
        },
        sendOrders(e) {
            // 手动派单
            const that = this;
            that.modalVisible = true;
            that.form.orderNumber = e.orderNumber;
            that.form.supplierNumberArray = [];
            that.form.supplierNumber = "";
        },
        saveSendOrder() {
            const that = this;
            if (that.form.supplierNumberArray.length > 1) {
                that.form["json"] = true;
                that.form.supplierNumber = that.form.supplierNumberArray[1];
                sendOrders(that.form).then(() => {
                    that.$message.success({
                        content: "手动派单成功！",
                        duration: 1,
                        onClose: () => {
                            that.modalVisible = false;
                            that.getList();
                        }
                    });
                });
            }
        },
        delOrder(e) {
            // 删除订单
            const that = this;
            that.$confirm({
                title: "是否确认删除该订单?",
                okType: "danger",
                okText: "确定",
                cancelText: "取消",
                centered: true,
                onOk() {
                    delOrder(e.id).then(() => {
                        that.$message.success({
                            content: "删除成功！",
                            duration: 1,
                            onClose: () => {
                                that.getList();
                            }
                        });
                    });
                },
                onCancel() {
                    console.log("Cancel");
                }
            });
        },
        followUpHandle(e) {
            // 跟进
            const that = this;
            that.modalRemarkVisible = true;
            that.remarkOrderNumber = e.orderNumber;
        },
        saveFollowUp() {
            const that = this;
            that.$confirm({
                title: "是否确认跟进?",
                okType: "danger",
                okText: "确定",
                cancelText: "取消",
                centered: true,
                onOk() {
                    followUp({
                        json: true,
                        followUpUserId: that.$store.state.userStore.id,
                        orderNumber: that.remarkOrderNumber,
                        remark: that.remark,
                    }).then(() => {
                        that.$message.success({
                            content: "跟进成功！",
                            duration: 1,
                            onClose: () => {
                                that.modalRemarkVisible = false;
                                that.getList();
                            }
                        });
                    });
                },
                onCancel() {
                    console.log("Cancel");
                }
            });
        },
        followUpListHandle(e) {
            // 跟进记录
            const that = this;
            that.followUpLoading = true;
            getByMerchantPhone(e.merchantPhone)
                .then(res => {
                    that.followUpLoading = false;
                    that.followUpData = res.data;
                    that.followUpListVisible = true;
                })
                .catch(err => {
                    console.log(err);
                    that.followUpLoading = false;
                });
        },
        orderFinish(e) {
            // 完成
            const that = this;
            that.finishRemarkVisible = true;
            //重置
            that.formFinish.finishOrderNumber = "";
            that.formFinish.remarkFinish = "";
            that.formFinish.imgUrls = [];
            that.fileList = [];
            //end
            that.form.finishOrderNumber = e.orderNumber;
            that.fileList = [{
                name: "上传图片",
                code: "otherImages",
                fileList: [],
                uploadList: []
            }];
        },
        saveFinish() {
            const that = this;
            this.$refs.finishForm.validate(valid => {
                if (valid) {
                    that.$confirm({
                        title: "是否确认完成?",
                        okType: "danger",
                        okText: "确定",
                        cancelText: "取消",
                        centered: true,
                        onOk() {
                            that.fileList.forEach(element => {
                                switch (element.code) {
                                    case "otherImages":
                                        if (element.uploadList && element.uploadList.length > 0) {
                                            that.formFinish.imgUrls = element.uploadList;
                                        } else {
                                            that.formFinish.imgUrls = [];
                                        }
                                        break;
                                }
                            });

                            finish({
                                json: true,
                                orderNumber: that.form.finishOrderNumber,
                                remark: that.formFinish.remarkFinish,
                                imgUrls: that.formFinish.imgUrls,
                            }).then(() => {
                                that.$message.success({
                                    content: "完成成功！",
                                    duration: 1,
                                    onClose: () => {
                                        that.modalRemarkVisible = false;
                                        that.finishRemarkVisible = false;
                                        that.getList();
                                    }
                                });
                            });
                        },
                        onCancel() {
                            console.log("Cancel");
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        downExport() {
            // 下载报表
            const that = this;
            downExport({
                keyWord: that.formInline.keyWord,
                type: that.formInline.type, //办证类型（1：平台代办；2：商户自办）
                source: that.formInline.source, //创建来源:全部->null；系统创建->1；人工创建->2）
                overdueStatus: that.formInline.overdueStatus, //逾期状态
                // status: that.formInline.status, //订单状态：0->待派单; 1->办证中;2->平台待审核;3->机构待审核:4->已完结,5:驳回(此处包含 平台驳回 和机构驳回)
                createTimeStart: that.formInline.createTimeStart,
                createTimeEnd: that.formInline.createTimeEnd,
                auditTimeBegin: that.formInline.auditTimeBegin,
                auditTimeEnd: that.formInline.auditTimeEnd,
                overTimeBegin: that.formInline.overTimeBegin,
                overTimeEnd: that.formInline.overTimeEnd,
                pageNum: that.pagination.current,
                pageSize: that.pagination.pageSize,
                downFile: true,
                _: new Date().getTime()
            }).then(res => {
                console.log(res)
                const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
                const a = document.createElement('a')
                a.href = URL.createObjectURL(blob)
                a.download = '办证订单报表.xls'
                a.click()
            });
        },
        fastDataChange() {
            let d = []

            // 近7天
            if (this.fastData === '0') {
                d = [moment().subtract('days', 6).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]

                this.formInline.createTimeStart = moment().subtract('days', 6).format('YYYY-MM-DD');
                this.formInline.createTimeEnd = moment().format('YYYY-MM-DD');
            }

            // 近30天
            if (this.fastData === '1') {
                d = [moment().subtract('days', 29).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]

                this.formInline.createTimeStart = moment().subtract('days', 29).format('YYYY-MM-DD');
                this.formInline.createTimeEnd = moment().format('YYYY-MM-DD');
            }

            this.freeDate = d
        },
        // 创建订单
        addEditOrderFn(e) {
            this.$refs.addEditOrder.onOpenOrderForm(e.id);
        },
        // 备注
        remarksFn(e) {
            this.$refs.remarks.onOpenOrderRemarks(e.id);
        },
        //重置
        onResetQuery() {
            const that = this;
            that.formInline.keyWord = ""
            that.formInline.status = 0
            that.formInline.createTimeStart = null
            that.formInline.createTimeEnd = null
            that.formInline.type = undefined
            that.formInline.source = undefined
            that.formInline.overdueStatus = undefined
            that.formInline.auditTimeBegin = null
            that.formInline.auditTimeEnd = null
            that.formInline.overTimeBegin = null
            that.formInline.overTimeEnd = null
            location.reload();
        },
        //提车指派
        savePickUpCarFn(e) {
            this.$refs.pickUpCar.onOpenOrderPickUpCar(e.id, e.orderNumber);
            console.log(e.id, e.orderNumber)
        },
        //编辑交车单
        updatePickUpCarFn(e) {
            this.visibleDeliverCar = true
            this.orderCurNumber = e.orderNumber
        },
        //关闭编辑交车单
        onCloseDeliverCar() {
            this.visibleDeliverCar = false
        },
        uploadDone(e) {
            const that = this;
            let uploadImgList = [];
            e.fileList.forEach(element => {
                uploadImgList.push(element.url);
            });
            that.fileList.forEach(item => {
                if (item.code == e.fileObj.code) {
                    //   获取修改的类型
                    item.uploadList = uploadImgList;
                }
            });
        },
        //打开过户类型变更
        onOpenTransferTypeFn(e) {
            const that = this;
            that.visibleTransferType = true;
            that.formTransferType.id = e.id;
            getTransferType(e.id).then(res => {
                that.transferTypeList = res.data;
            }).catch(err => {
                console.log(err);
            });
        },
        //修改订单过户类型
        onChangeTransferTypeFn() {
            const that = this;
            that.$refs.refFormTransferType.validate(valid => {
                if (valid) {
                    editTransferType(that.formTransferType.id, {
                        transferType: that.formTransferType.transferType,
                    }).then(res => {
                        console.log(res);
                        that.visibleTransferType = false;
                        that.$refs.refFormTransferType.resetFields();
                        that.$message.success({
                            content: "操作成功！",
                            duration: 1,
                            onClose: () => {
                                that.getList();
                            }
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        //打开供应商变更
        onOpenSupplier(e) {
            const that = this;
            that.visibleSupplier = true;
            that.form.orderNumber = e.id;
            that.form.supplierNumberArray = [];
            that.form.supplierNumber = "";
            getSupplier(e.id).then(res => {
                that.supplierName = res.data.supplierName;
            }).catch(err => {
                console.log(err);
            });
        },
        // 修改订单状态
        changeOrderStatus(e){
            const that = this;
            that.changeOrderStatusId = e.id;
            getCertificationOrdersStatusInfo(e.id).then(res=>{
                that.orderStatusInfo = res.data;
                that.visitOrderStatus = true;
            }).catch(err => {
                console.log(err);
            });
        },
        hideVisitOrder(){
            this.orderStatus = null;
            this.visitOrderStatus = false;
        },
        submitOrderStatus(){
            const that = this;
            if(that.orderStatus == null){
                that.$message.error({
                    content: "请选择要变更的状态",
                    duration: 1,
                });
                return;
            }
            changeCertificationOrders(that.changeOrderStatusId,{
                status:that.orderStatus
            }).then(res => {
                that.$message.success({
                    content: "操作成功！",
                    duration: 1,
                    onClose: () => {
                        that.orderStatus = null;
                        that.visitOrderStatus = false;
                        that.getList();
                    }
                });
            }).catch(err => {
                console.log(err);
            });
        },
        //修改订单供应商
        onChangeSupplier() {
            const that = this;
            if (that.form.supplierNumberArray.length > 1) {
                editSupplier(that.form.orderNumber, {
                    supplierNumber: that.form.supplierNumberArray[1],
                }).then(res => {
                    console.log(res);
                    that.visibleSupplier = false;
                    that.$refs.ruleForm.resetFields();
                    that.$message.success({
                        content: "操作成功！",
                        duration: 1,
                        onClose: () => {
                            that.getList();
                        }
                    });
                });
            }
        },
    }
};
</script>
<style lang="scss">
.ml_drawer_class .ant-drawer-content-wrapper .ant-drawer-body {
    padding: 0;
}
.ml_visitOrderStatus_content{
    margin-top: 15px;
    .label{
        display: inline-block;
        width: 100px;
        text-align: right;
        padding-right: 10px;
    }
}
</style>