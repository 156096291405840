<!--
 * @Descripttion : 办证订单-新增备注
 * @version      : 
 * @Author       : karl
 * @Date         : 2021-06-25 10:20:38
 * @LastEditors  : karl
 * @LastEditTime : 
-->
<template>
    <div>
        <!-- 备注 -->
        <a-drawer :title="title" width="600px" :visible="visible" @close="onCloseOrderRemarks" maskClosable>

            <a-form-model ref="formRemarks" :model="form" :rules="rules" layout="inline">
                <a-form-model-item prop="content">
                    <a-textarea v-model="form.content" placeholder="请输入备注" :rows="3" style="width: 400px" allowClear />
                </a-form-model-item>
            </a-form-model>

            <div>
                <a-button type="primary" :disabled="isSubmitDisable" @click="onSubmitRemarksForm">保存</a-button>
            </div>

            <h3 style="padding:30px 0 10px 0;">备注历史</h3>

            <a-table size="middle" bordered :columns="columns" :data-source="data" :loading="loading" :pagination="pagination" @change="handleTableChange"></a-table>

        </a-drawer>
        <!-- end_备注 -->
    </div>
</template>
        
<script>
import {
    getRemarks,
    addRemarks,
} from "@/api/order";

const columns = [
    { title: "时间", dataIndex: "createTime", align: "center", width: 150 },
    { title: "备注人", dataIndex: "createBy", align: "center", width: 100 },
    { title: "内容", dataIndex: "content", align: "center" },
];



export default {
    name: "remarks",
    props: {},
    data() {
        return {
            columns,
            data: [],
            loading: false,
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0
            },
            id: '',
            title: '',
            visible: false,
            form: {},
            rules: {
                content: [{ required: true, message: "请输入备注" }],
            },
            isSubmitDisable: false, //阻止重复提交
        }
    },
    mounted() {

    },
    methods: {
        // 关闭drawer
        onCloseOrderRemarks() {
            this.visible = false;
            this.form = {};
        },
        // 打开drawer
        onOpenOrderRemarks(id) {
            const that = this;
            that.title = "订单备注";
            that.visible = true;
            that.id = id;
            that.form = {
                certificationOrderId: id,
                content: ''
            };

            that.getList();
        },
        //保存
        onSubmitRemarksForm() {
            const that = this;
            that.loading = true;
            that.isSubmitDisable = true;
            that.$refs.formRemarks.validate((valid) => {
                if (valid) {
                    //新增 
                    addRemarks(that.form).then(() => {
                        that.loading = false;
                        setTimeout(() => {
                            that.isSubmitDisable = false; //阻止重复提交
                        }, 1000)
                        that.$message.success({
                            content: "添加成功！",
                            duration: 1,
                            onClose: () => {
                                this.getList();
                            },
                        });
                    }).catch((err) => {
                        that.isSubmitDisable = false; //阻止重复提交
                        that.loading = false;
                        console.log('失败：' + err);
                    });
                } else {
                    that.isSubmitDisable = false;
                    that.loading = false;
                    console.log('校验失败')
                }
            })

        },
        getList() {
            const that = this;
            that.loading = true;
            getRemarks(
                that.id + '/remarks?pageNum=' + that.pagination.current + '&pageSize=' + that.pagination.pageSize, {
                _: new Date().getTime()
            }).then(res => {
                that.loading = false;
                that.data = res.data.list;
                that.pagination.total = res.data.total;

            }).catch(err => {
                console.log('失败：' + err);
                that.loading = false;
            })
        },
        handleTableChange(pagination, filters, sorter) {
            console.log(pagination, filters, sorter);
            this.pagination = { ...pagination };
            this.getList();
        },

        //getRemarks

    },
}
</script>

<style lang="scss">
</style>