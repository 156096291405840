<!--
 * @Descripttion : 办证订单-新增/编辑订单
 * @version      : 
 * @Author       : karl
 * @Date         : 2021-06-25 10:20:38
 * @LastEditors  : karl
 * @LastEditTime : 
-->
<template>
    <div>
        <!-- 新增/编辑 -->
        <a-drawer :title="title" width="calc(100% - 200px)" :visible="visible" @close="onCloseOrderForm" maskClosable>
            <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="horizontal" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">

                <a-form-model-item label="买方姓名" prop="merchantName">
                    <a-input v-model="form.merchantName" placeholder="请输入买方姓名" class="f_wid" allowClear />
                </a-form-model-item>

                <a-form-model-item label="联系电话" prop="merchantPhone">
                    <a-input v-model="form.merchantPhone" placeholder="请输入联系电话" class="f_wid" allowClear />
                </a-form-model-item>

                <a-form-model-item label="车牌号码" prop="carPlate">
                    <a-input v-model="form.carPlate" placeholder="请输入车牌号码" class="f_wid" allowClear />
                </a-form-model-item>

                <a-form-model-item label="VIN码" prop="carVin">
                    <a-input v-model="form.carVin" placeholder="请输入VIN码" class="f_wid" allowClear />
                    <a-button @click="onResolveVIN" style="margin-left: 15px">
                        解析
                    </a-button>
                </a-form-model-item>

                <a-form-model-item label="车型" prop="carModelId">
                    <a-select style="width: 120px; margin-right: 15px" @change="handleCarBrandChange" v-model="form.carBrandId" allowClear placeholder="请选择品牌">
                        <a-select-opt-group v-for="item in carBrandList" :key="item.groupName">
                            <a-select-option v-for="items in item.values" :key="items.id" :name="items.name">
                                {{ items.name }}
                            </a-select-option>
                        </a-select-opt-group>
                    </a-select>

                    <a-select style="width: 120px; margin-right: 15px" @change="handleCarSeriesChange" v-model="form.carSeriesId" allowClear placeholder="请选择车系">
                        <a-select-opt-group v-for="item in carSeriesList" :key="item.groupName">
                            <a-select-option v-for="items in item.values" :key="items.id" :name="items.name" :catalogId="item.groupId">
                                {{ items.name }}
                            </a-select-option>
                        </a-select-opt-group>
                    </a-select>

                    <a-select style="width: 120px; margin-right: 15px" @change="handleCarYearChange" v-model="form.carYear" allowClear placeholder="请选择年款">
                        <a-select-option v-for="item in carYearList" :key="item.fldYearCotyName" :name="item.fldYearCotyName">
                            {{ item.fldYearCotyName }}
                        </a-select-option>
                    </a-select>

                    <a-select style="width: 300px" @change="handleCarModelChange" v-model="form.carModelId" allowClear placeholder="请选择车型">
                        <a-select-option v-for="item in carModelList" :key="item.id" :name="item.name">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="办证类型" prop="type">
                    <a-select style="width: 260px" v-model="form.type" allowClear placeholder="请选择" @change="onChangeType">
                        <a-select-option :value="1">平台办理</a-select-option>
                        <a-select-option :value="2">商户自办</a-select-option>
                    </a-select>
                </a-form-model-item>

                <div v-if="visibleTransferType">
                    <a-form-model-item label="过户类型" prop="transferType">
                        <a-select style="width: 260px" v-model="form.transferType" allowClear placeholder="请选择">
                            <a-select-option :value="1">本地过户</a-select-option>
                            <a-select-option :value="2">转籍提档</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>

                <a-form-model-item label="办证说明" prop="certificationDeclare">
                    <a-textarea v-model="form.certificationDeclare" placeholder="请输入过户说明" :rows="3" style="width: 400px" allowClear />
                </a-form-model-item>

            </a-form-model>
            <!--end_表单-->
            <div class="ml_drawer_footer">
                <a-button type="primary" @click="onSubmitOrderForm" :disabled="isSubmitDisable"> 保存 </a-button>
                <a-button @click="onCloseOrderForm"> 取消 </a-button>
            </div>
        </a-drawer>
        <!-- end_新增/编辑 -->
    </div>
</template>
        
<script>
import {
    addCertificationOrder, //新增系统办证订单
    getCertificationOrder, //获取系统办证订单
    updateCertificationOrder, //修改系统办证订单
} from "@/api/order";
import {
    ResolveVIN, //VIN解析
    GetListFirstCharValues, //获取品牌
    GetCatalogAndCarSeriesByBrandID, //获取车系
    GetYearBySeriesID, //获取年款
    GetCarModelBySeriesIDAndYear, //获取车型
} from "@/api/saasV5";
import { validatePhoneThree } from "@/utils/verification";


export default {
    name: "carInfoAddEdit",
    props: {},
    data() {
        return {
            id: "",
            title: "",
            visible: false,
            form: {},
            rules: {
                merchantName: [{ required: true, message: "请输入买方姓名" }],
                merchantPhone: [{ required: true, validator: validatePhoneThree }],
                carPlate: [{ required: true, message: "请输入车牌号" }],
                carVin: [{ required: true, message: "请输入VIN码" }],
                carBrandId: [{ required: true, message: "请选择品牌" }],
                carSeriesId: [{ required: true, message: "请选择车系" }],
                carYear: [{ required: true, message: "请选择年款" }],
                carModelId: [{ required: true, message: "请选择车型" }],
                type: [{ required: true, message: "请选择办证类型" }],
                certificationDeclare: [{ required: true, message: "请输入办证说明" }],
            },
            options: [],
            fieldNames: {
                label: "name",
                value: "id",
                children: "values",
            },
            //下拉选项初始数据
            carBrandList: [],
            carSeriesList: [],
            carYearList: [],
            carModelList: [],
            //end_下拉选项初始数据
            isSubmitDisable: false, //阻止重复提交
            visibleTransferType: false, //是否显示过户类型
        }
    },
    mounted() {

    },
    methods: {
        init() {
            //数据初始化
            const that = this;

            GetListFirstCharValues({
                //获取品牌
                sessionId: "",
            }).then(res => {
                that.carBrandList = res.data;
            }).catch(err => {
                console.log('失败：' + err);
            });

        },
        onCloseOrderForm() {
            //关闭创建订单
            const that = this;
            that.visible = false;
            that.form = {};
            that.isSubmitDisable = false; //阻止重复提交
        },
        onOpenOrderForm(id) {
            //打开创建订单
            const that = this;
            that.init();
            that.form = {
                id: "",
                merchantName: "",
                merchantPhone: "",
                carPlate: "",
                carVin: "",
                carBrandId: undefined,
                carBrandName: "",
                carSeriesId: undefined,
                carSeriesName: "",
                carYear: undefined,
                carModelId: undefined,
                carModelName: "",
                type: undefined,
                transferType: undefined,
                certificationDeclare: "",
            }
            if (id) {
                //编辑
                that.title = "编辑订单";
                that.form.id = id;
                that.getCertificationOrder();
            } else {
                //新增
                that.title = "新增订单";
            }
            that.visible = true;
            that.$nextTick(() => {
                that.$refs.ruleForm.resetFields();
            });
        },
        onSubmitOrderForm() {
            //保存创建订单
            const that = this;
            that.loading = true;
            that.isSubmitDisable = true;
            that.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (that.form.id) {
                        //编辑
                        that.isSubmitDisable = false;
                        updateCertificationOrder(that.form).then(() => {
                            that.loading = false;
                            setTimeout(() => {
                                that.isSubmitDisable = false; //阻止重复提交
                            }, 2000)
                            that.$message.success({
                                content: "添加成功！",
                                duration: 1,
                                onClose: () => {
                                    that.visible = false;
                                    that.$emit("refresh");
                                },
                            });
                        }).catch((err) => {
                            that.isSubmitDisable = true; //阻止重复提交
                            console.log('失败：' + err);
                        });
                    } else {
                        that.isSubmitDisable = false; //阻止重复提交
                        //新增 
                        addCertificationOrder(that.form).then(() => {
                            that.loading = false;
                            setTimeout(() => {
                                that.isSubmitDisable = false; //阻止重复提交
                            }, 2000)
                            that.$message.success({
                                content: "添加成功！",
                                duration: 1,
                                onClose: () => {
                                    that.visible = false;
                                    that.$emit("refresh");
                                },
                            });
                        }).catch((err) => {
                            that.isSubmitDisable = true; //阻止重复提交
                            console.log('失败：' + err);
                        });
                    }
                } else {
                    that.isSubmitDisable = false;
                    console.log('校验失败')
                }
            })
        },
        getCertificationOrder() {
            //根据id获取系统办证订单
            const that = this;
            that.loading = true;
            getCertificationOrder(that.form.id, {
                _: new Date().getTime()
            }).then(res => {
                that.loading = false;
                that.form.merchantName = (res.data.merchantName || "");
                that.form.merchantPhone = (res.data.merchantPhone || "");
                that.form.carVin = (res.data.carVin || "");
                that.form.carPlate = (res.data.carPlate || "");
                that.form.carBrandId = (res.data.carBrandId ? Number(res.data.carBrandId) : undefined);
                that.form.carBrandName = (res.data.carBrandName || "");
                that.form.carSeriesId = (res.data.carSeriesId ? Number(res.data.carSeriesId) : undefined);
                that.form.carSeriesName = (res.data.carSeriesName || "");
                that.form.carYear = (res.data.carYear ? Number(res.data.carYear) : undefined);
                that.form.carModelId = (res.data.carModelId ? Number(res.data.carModelId) : undefined);
                that.form.carModelName = (res.data.carModelName || "");
                if (res.data.carBrandId) {
                    GetCatalogAndCarSeriesByBrandID({
                        brandID: res.data.carBrandId,
                    }).then((res) => {
                        that.carSeriesList = res.data;
                    });
                }
                if (res.data.carSeriesId) {
                    GetYearBySeriesID({
                        seriesId: res.data.carSeriesId,
                    }).then((res) => {
                        that.carYearList = res.data;
                    });
                }
                if (res.data.carYear) {
                    GetCarModelBySeriesIDAndYear({
                        seriesId: res.data.carSeriesId,
                        year: res.data.carYear,
                    }).then((res) => {
                        this.carModelList = res.data;
                    });
                }
                that.form.type = (res.data.type ? Number(res.data.type) : undefined);
                that.form.transferType = (res.data.transferType ? Number(res.data.transferType) : undefined);
                that.form.certificationDeclare = (res.data.certificationDeclare || "");
                if (Number(res.data.type) == 1) { //平台办理
                    that.visibleTransferType = true;
                    that.rules = {
                        merchantName: [{ required: true, message: "请输入买方姓名" }],
                        merchantPhone: [{ required: true, validator: validatePhoneThree }],
                        carPlate: [{ required: true, message: "请输入车牌号" }],
                        carVin: [{ required: true, message: "请输入VIN码" }],
                        carBrandId: [{ required: true, message: "请选择品牌" }],
                        carSeriesId: [{ required: true, message: "请选择车系" }],
                        carYear: [{ required: true, message: "请选择年款" }],
                        carModelId: [{ required: true, message: "请选择车型" }],
                        type: [{ required: true, message: "请选择办证类型" }],
                        certificationDeclare: [{ required: true, message: "请输入办证说明" }],
                        transferType: [{ required: true, message: "请选择过户类型" }],
                    };
                } else {
                    that.visibleTransferType = false;
                    that.form.transferType = undefined;
                }
            }).catch(err => {
                console.log('失败：' + err);
                that.loading = false;
            });
        },
        onResolveVIN() {
            //vin码解析
            const that = this;
            if (that.form.carVin) {
                that.carSeriesList = [];
                that.carYearList = [];
                that.carModelList = [];

                //str_ResolveVIN
                ResolveVIN({
                    vin: that.form.carVin,
                }).then(res => {
                    if (res.code >= 0) {
                        // that.form.carName = res.data.carName || "";
                        that.form.carBrandId = (res.data.brandID ? Number(res.data.brandID) : undefined);
                        that.form.carBrandName = res.data.brandName || "";
                        // that.form.catalogId = res.data.catalogID || "";
                        that.form.carSeriesId = (res.data.seriesID ? Number(res.data.seriesID) : undefined);
                        that.form.carSeriesName = res.data.carSession || "";
                        that.form.carYear = (res.data.year ? Number(res.data.year) : undefined);
                        that.form.carModelId = (res.data.modelID ? Number(res.data.modelID) : undefined);
                        that.form.carModelName = res.data.modelName || "";

                        if (res.data.brandID) {
                            GetCatalogAndCarSeriesByBrandID({
                                brandID: res.data.brandID,
                            }).then((res) => {
                                that.carSeriesList = res.data;
                            });
                        }

                        if (res.data.seriesID) {
                            GetYearBySeriesID({
                                seriesId: res.data.seriesID,
                            }).then((res) => {
                                that.carYearList = res.data;
                            });
                        }
                        if (res.data.year) {
                            GetCarModelBySeriesIDAndYear({
                                seriesId: res.data.seriesID,
                                year: res.data.year,
                            }).then((res) => {
                                this.carModelList = res.data;
                            });
                        }
                    } else {
                        that.$message.error({
                            content: res.message,
                            duration: 2,
                        });
                    }
                }).catch(err => {
                    console.log('失败：' + err);
                });
                //end_ResolveVIN

            } else {
                that.$message.error({
                    content: "请输入VIN码",
                    duration: 2,
                });
            }
        },
        handleCarBrandChange(value, options) {
            //品牌选择
            const that = this;
            if (value) {
                that.form.carBrandId = value;
                that.form.carBrandName = options.data.attrs.name;
                // that.form.catalogId = "";
                that.form.carSeriesId = undefined;
                that.form.carSeriesName = "";
                that.form.carYear = undefined;
                that.form.carModelId = undefined;
                that.form.carModelName = "";
                that.carYearList = [];
                that.carModelList = [];
                GetCatalogAndCarSeriesByBrandID({
                    brandID: value,
                }).then((res) => {
                    this.carSeriesList = res.data;
                }).catch((err) => {
                    console.log('失败：' + err);
                });
            } else {
                that.$message.error({
                    content: "请选择品牌",
                    duration: 1.5,
                });
                that.form.carSeriesId = undefined;
                that.form.carSeriesName = "";
                that.form.carYear = undefined;
                that.form.carModelId = undefined;
                that.form.carModelName = "";
                that.carYearList = [];
                that.carModelList = [];
            }
        },
        handleCarSeriesChange(value, options) {
            //车系选择
            const that = this;
            if (value) {
                that.form.carSeriesId = value;
                that.form.carSeriesName = options.data.attrs.name;
                // that.form.catalogId = options.data.attrs.catalogId;
                that.form.carYear = undefined;
                that.form.carModelId = undefined;
                that.form.carModelName = "";
                that.carModelList = [];
                GetYearBySeriesID({
                    seriesId: value,
                }).then((res) => {
                    this.carYearList = res.data;
                }).catch((err) => {
                    console.log('失败：' + err);
                });
            } else {
                that.$message.error({
                    content: "请选择车系",
                    duration: 1.5,
                });
                that.form.carYear = undefined;
                that.form.carModelId = undefined;
                that.form.carModelName = "";
                that.carModelList = [];
            }

        },
        handleCarYearChange(value) {
            //年款选择
            const that = this;
            if (!value) {
                that.$message.error({
                    content: "请选择年款",
                    duration: 1.5,
                });
                that.form.carModelId = undefined;
                that.form.carModelName = "";
            } else {
                that.form.carYear = value;
                that.form.carModelId = undefined;
                that.form.carModelName = "";
                GetCarModelBySeriesIDAndYear({
                    seriesId: that.form.carSeriesId,
                    year: value,
                }).then((res) => {
                    this.carModelList = res.data;
                }).catch((err) => {
                    console.log('失败：' + err);
                });
            }
        },
        handleCarModelChange(value, options) {
            //车型选择
            const that = this;
            if (value) {
                that.form.carModelId = value;
                that.form.carModelName = options.data.attrs.name;
                // that.form.carName = that.form.brandName + " " + that.form.seriesName + " " + that.form.year + " " + that.form.modelName;
            } else {
                that.$message.error({
                    content: "请选择车型",
                    duration: 1.5,
                });
            }
        },
        onChangeType() {
            //办证类型
            const that = this;
            if (that.form.type == 1) { //平台办理
                that.visibleTransferType = true;
                that.rules = {
                    merchantName: [{ required: true, message: "请输入买方姓名" }],
                    merchantPhone: [{ required: true, validator: validatePhoneThree }],
                    carPlate: [{ required: true, message: "请输入车牌号" }],
                    carVin: [{ required: true, message: "请输入VIN码" }],
                    carBrandId: [{ required: true, message: "请选择品牌" }],
                    carSeriesId: [{ required: true, message: "请选择车系" }],
                    carYear: [{ required: true, message: "请选择年款" }],
                    carModelId: [{ required: true, message: "请选择车型" }],
                    type: [{ required: true, message: "请选择办证类型" }],
                    certificationDeclare: [{ required: true, message: "请输入办证说明" }],
                    transferType: [{ required: true, message: "请选择过户类型" }],
                };
            } else {
                that.visibleTransferType = false;
                that.form.transferType = undefined;
            }
        },
    },
}
</script>

<style lang="scss">
.f_wid {
    width: 260px;
}
.ant-form-item {
    margin-top: 15px;
}
</style>