<!--
 * @Descripttion : 提车指派(新增/编辑) 办证订单列表
 * @version      : 
 * @Author       : karl
 * @Date         : 2022-01-06 10:20:38
 * @LastEditors  : karl
 * @LastEditTime : 
-->
<template>
    <div>
        <!-- 新增/编辑 -->
        <a-drawer :title="title" width="600px" :destroyOnClose="true" :visible="visible" @close="onCloseDrawer" maskClosable>
            <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="horizontal" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">

                <a-form-model-item label="提车人姓名" prop="pickUpCarRealName">
                    <a-input v-model="form.pickUpCarRealName" placeholder="请输入提车人姓名" class="f_wid" allowClear />
                </a-form-model-item>

                <a-form-model-item label="手机号码" prop="pickUpCarUserPhone">
                    <a-input v-model="form.pickUpCarUserPhone" placeholder="请输入手机号码" class="f_wid" allowClear />
                </a-form-model-item>

                <a-form-model-item label="业务编号" prop="">
                    <a-input v-model="form.businessCode" placeholder="请输入业务编号" class="f_wid" allowClear />
                </a-form-model-item>

                <a-form-model-item label="指派备注" prop="">
                    <a-textarea v-model="form.assignRemark" placeholder="请输入指派备注" :rows="3" style="width: 90%" allowClear />
                </a-form-model-item>

                <a-form-model-item label="附件图片" prop="">
                    <template v-for="(items,indexs) in fileList">
                        <filesUpload :key="indexs" :fileObj="items" @onChange="uploadDone"></filesUpload>
                    </template>
                </a-form-model-item>

            </a-form-model>
            <!--end_表单-->
            <div class="ml_drawer_footer">
                <a-button type="primary" @click="onSubmitDrawer" :disabled="isSubmitDisable"> 保存 </a-button>
                <a-button @click="onCloseDrawer"> 取消 </a-button>
            </div>
        </a-drawer>
        <!-- end_新增/编辑 -->
    </div>
</template>
        
<script>
import {
    getPickUpCar,
    savePickUpCar,
} from "@/api/pickUpCar";

import filesUpload from "@/components/pickUpCar/filesUpload";

import { validatePhoneThree } from "@/utils/verification";



export default {
    name: "pickUpCarForm",
    components: {
        filesUpload
    },
    props: {},
    data() {
        return {
            id: "",
            title: "提车指派",
            visible: false,
            form: {
                id: "",
                certificationOrderId: "",
                certificationOrderNumber: "",
                pickUpCarRealName: "",
                pickUpCarUserPhone: "",
                businessCode: "",
                assignRemark: "",
                images: []
            },
            rules: {
                pickUpCarRealName: [{ required: true, message: "请输入提车人姓名" }],
                pickUpCarUserPhone: [{ required: true, validator: validatePhoneThree }],
            },
            isSubmitDisable: false, //阻止重复提交
            fileList: [], //上传图片
        }
    },
    mounted() {

    },
    methods: {
        onCloseDrawer() {
            const that = this;
            that.visible = false;
            that.form = {
                id: "",
                certificationOrderId: "",
                certificationOrderNumber: "",
                pickUpCarRealName: "",
                pickUpCarUserPhone: "",
                businessCode: "",
                assignRemark: "",
                images: []
            };
            that.isSubmitDisable = false; //阻止重复提交
        },
        onOpenOrderPickUpCar(orderId, orderNumber) {
            const that = this;
            that.form.certificationOrderId = orderId;
            that.form.certificationOrderNumber = orderNumber;
            that.getDetail(orderNumber);
        },
        onSubmitDrawer() {
            //保存创建订单
            const that = this;
            that.loading = true;
            that.isSubmitDisable = true;
            that.$refs.ruleForm.validate((valid) => {
                if (valid) {

                    that.fileList.forEach(element => {
                        switch (element.code) {
                            case "otherImages":
                                if (element.uploadList && element.uploadList.length > 0) {
                                    that.form.images = element.uploadList;
                                } else {
                                    that.form.images = [];
                                }
                                break;
                        }
                    });

                    savePickUpCar(that.form).then(() => {
                        that.loading = false;
                        setTimeout(() => {
                            that.isSubmitDisable = false; //阻止重复提交
                        }, 2000)
                        that.$message.success({
                            content: "操作成功！",
                            duration: 1,
                            onClose: () => {
                                that.form = {
                                    id: "",
                                    certificationOrderId: "",
                                    certificationOrderNumber: "",
                                    pickUpCarRealName: "",
                                    pickUpCarUserPhone: "",
                                    businessCode: "",
                                    assignRemark: "",
                                    images: []
                                };
                                that.onCloseDrawer();
                                that.visible = false;
                                that.$emit("refresh");
                            },
                        });
                    }).catch((err) => {
                        that.isSubmitDisable = false; //阻止重复提交
                        console.log('失败：' + err);
                    });
                } else {
                    that.isSubmitDisable = false;
                    console.log('校验失败')
                }
            })
        },
        getDetail(orderNumber) {
            const that = this;
            that.loading = true;
            getPickUpCar(orderNumber, {
                _: new Date().getTime()
            }).then(res => {
                that.loading = false;
                if (res.code == 200 && res.data) {
                    that.form = res.data;

                    that.fileList = [{
                        name: "其他照片",
                        code: "otherImages",
                        fileList: [],
                        uploadList: []
                    }];
                    if (res.data.images) {
                        res.data.images.forEach((item, index) => {
                            that.fileList[0].fileList.push({
                                uid: 'otherImages' + (index + 1),
                                name: null,
                                status: "done",
                                response: "",
                                url: item
                            })
                        });
                        that.fileList[0].uploadList = res.data.images
                    }

                    that.$nextTick(() => {
                        that.$refs.ruleForm.resetFields();
                    });
                    that.visible = true;
                } else {
                    that.fileList = [{
                        name: "其他照片",
                        code: "otherImages",
                        fileList: [],
                        uploadList: []
                    }];
                    that.$nextTick(() => {
                        that.$refs.ruleForm.resetFields();
                    });
                    that.visible = true;
                }
            }).catch(err => {
                console.log('失败:' + err);
                that.loading = false;
            });
        },
        uploadDone(e) {
            const that = this;
            let uploadImgList = [];
            e.fileList.forEach(element => {
                uploadImgList.push(element.url);
            });
            that.fileList.forEach(item => {
                if (item.code == e.fileObj.code) {
                    //   获取修改的类型
                    item.uploadList = uploadImgList;
                }
            });
        }
    },
}
</script>

<style lang="scss">
.f_wid {
    width: 260px;
}
.ant-form-item {
    margin-top: 15px;
}
</style>