/*
 * @Descripttion :
 * @version      :
 * @Author       : ml
 * @Date         : 2020-09-04 15:13:28
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-04 15:14:27
 */
//

import request from './request'

// 供应商列表
export function GetProvinceAndCityAndAreaList(data) {
	return request.post(
		process.env.VUE_APP_SAASV5_API +
		'api/PublicData/GetProvinceAndCityAndAreaList',
		data
	)
}

// VIN码解析
export function ResolveVIN(data) {
	data["json"] = true;
	return request.post(process.env.VUE_APP_SAASV5_API + "/api/ResolveVIN/ResolveVIN", data);
}

// 获取品牌
export function GetListFirstCharValues(data) {
	data["json"] = true;
	return request.post(process.env.VUE_APP_SAASV5_API + "/api/PublicCarData/GetListFirstCharValues", data);
}

// 获取车系
export function GetCatalogAndCarSeriesByBrandID(data) {
	data["json"] = true;
	return request.post(process.env.VUE_APP_SAASV5_API + "/api/PublicCarData/GetCatalogAndCarSeriesByBrandID", data);
}

// 获取年款
export function GetYearBySeriesID(data) {
	data["json"] = true;
	return request.post(process.env.VUE_APP_SAASV5_API + "/api/PublicCarData/GetYearBySeriesID", data);
}

// 获取车型
export function GetCarModelBySeriesIDAndYear(data) {
	data["json"] = true;
	return request.post(process.env.VUE_APP_SAASV5_API + "/api/PublicCarData/GetCarModelBySeriesIDAndYear", data);
}


